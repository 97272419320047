.pop-up-object {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 8px;
  font-size: clamp(10px, 1.2vw, 14px);
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
  width: clamp(120px, 25%, 250px);
  max-height: 50vh;
  user-select: none;
  overflow: auto;
  border: 1px solid transparent;
}

.pop-up-content {
  padding: clamp(8px, 1.5vw, 16px);
  line-height: 1.3;
  max-height: calc(50vh - 32px);
  overflow-y: auto;
}

.pop-up-object:hover,
.pop-up-object.hover {
  transform: scale(1.02);
  border-color: rgba(var(--glow-color-rgb), 0.7);
  box-shadow: 
    0 0 10px rgba(var(--glow-color-rgb), 0.5), 
    0 0 20px rgba(var(--glow-color-rgb), 0.3);
}

.pop-up-object.fade-in {
  opacity: 1;
  pointer-events: auto;
}

.pop-up-object.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Remove all arrow-related styles */

.pop-up-object.fade-in {
  opacity: 1;
  pointer-events: auto;
}

.pop-up-object.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Scrollbar styles */
.pop-up-content::-webkit-scrollbar {
  width: 3px;
}

.pop-up-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.pop-up-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1.5px;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .pop-up-object {
    font-size: 10px;
    width: clamp(80px, 35%, 180px);
    max-height: 32vh; /* Reduced by 20% from 40vh */
  }

  .pop-up-content {
    padding: 6px;
    max-height: calc(32vh - 12px);
    line-height: 1.2; /* Slightly reduced line height for mobile */
  }
}
