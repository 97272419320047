/* Updated Color Scheme */
:root {
  --color-background: #1e1e1e; /* Default background */
  --color-text: #ffffff; /* White text */
  --color-primary: #ff4500; /* Bright orange/red for accents */
  --color-secondary: #ff6347; /* Slightly lighter orange/red */
  --color-accent: #666666; /* Muted grey for borders and secondary elements */
  --color-card-bg: #333333; /* Dark grey for cards or input backgrounds */
  --color-input-bg: #444444; /* Slightly lighter dark grey for inputs */
  --color-border: #555555; /* Mid grey for borders */
  --color-section-1-bg: #1e1e1e; /* Background for section 1 */
  --color-section-2-bg: #2a2a2a; /* Background for section 2 */
  --color-section-3-bg: #3a3a3a; /* Background for section 3 */
  --color-section-4-bg: #2a2a2a; /* Background for section 4 */
  --font-primary: 'Poppins', Helvetica, Arial, sans-serif;
  --font-secondary: 'Montserrat', Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: 'Poppins';
  src: url('/public/fonts/Poppins-Regular.woff2') format('woff2'),
       url('/public/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-Regular.woff2') format('woff2'),
       url('/public/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


/* Global Styles */
html {
  scroll-behavior: smooth;
}

section {
  padding: 4rem 0; /* Increased padding for better spacing */
}

body {
  font-family: var(--font-primary);
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-primary);
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-secondary);
  text-decoration: underline;
}

button {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:hover {
  /* background-color: var(--color-accent); */
}

/* Navigation Styles */
.nav-container {
  position: fixed; /* Make the navigation bar sticky */
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(42, 42, 42, 0.8); /* Make the background transparent */
  padding: 1rem 0; /* Reduced padding from 15px to 5px */
  z-index: 1000; /* Ensure the navigation bar is on top of other content */
}

.nav-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-link {
  color: #CCCCCC;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 1rem 0; /* Add some vertical padding to maintain clickable area */
}

.nav-link:hover,
.nav-link.active {
  color: #FF4500;
}

/* Adjust the body padding to match the new nav height */
body {
  padding-top: 2rem; /* Reduced from 60px to 40px, adjust as needed */
}

/* Content Styles */
.container {
  padding: 30px 20px; /* Reduced padding to bring sections closer together */
  max-width: 1200px;
  margin: 0 auto;
}

/* Card Styles */
.card {
  background-color: var(--color-card-bg);
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--color-text);
}

input, textarea {
  background-color: var(--color-input-bg);
  border: 1px solid var(--color-border);
  color: var(--color-text);
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

textarea {
  resize: vertical;
  min-height: 120px;
}

/* Footer Styles */
.footer {
  background-color: var(--color-background);
  color: var(--color-text);
  text-align: center;
  padding: 30px 0;
  border-top: 2px solid var(--color-primary);
  user-select: none;
}

.footer a {
  user-select: none;
  -webkit-user-drag: none;
}

/* Remove this style or comment it out */
/* .footer-link {
  user-select: text;
  -webkit-user-drag: none;
} */

/* Add this new style for social icons */
.footer-social-icon {
  width: 24px;
  height: 24px;
  fill: var(--color-primary);
  transition: fill 0.3s ease;
}

.footer-social-icon:hover {
  fill: var(--color-secondary);
}

/* Utility Classes */
.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.bg-primary {
  background-color: var(--color-primary);
  color: var(--color-background);
}

.bg-secondary {
  background-color: var(--color-secondary);
  color: var(--color-background);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-list {
    flex-direction: column;
    align-items: center;
  }

  .nav-item {
    margin-bottom: 10px;
  }

  .pricing-plan {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  /* Reduce top padding for hero section on mobile */
  #home {
    padding-top: 0rem; /* Adjust this value as needed */
  }

  /* Make sure the mobile logo is smaller */
  .mobile-nav-container img {
    height: 140px !important; /* Adjust this value as needed */
    width: auto !important;
  }
}

/* Section Styles */
#buy-now {
  padding: 2rem 0; /* Less padding for the buy-now section */
  user-select: none;
}

#features {
  padding-top: 2rem; /* Reduced top padding for features section */
}

/* Global text selection prevention */
h1, h2, h3, h4, h5, h6, p, li, span {
  user-select: text;
  font-family: var(--font-primary);
}

/* Section Backgrounds */
.section-1 {
  background-color: var(--color-section-1-bg);
}

.section-2 {
  background-color: var(--color-section-2-bg);
}

.section-3 {
  background-color: var(--color-section-3-bg);
}

.section-4 {
  background-color: var(--color-section-4-bg);
}
