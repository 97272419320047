/* Guide.css */

/* Guide Container */
.guide-container {
  position: relative;
  width: 100%;
  max-width: 1050px; /* Slightly larger to give more "volume" */
  margin: 0 auto; /* Center the container horizontally */
  padding: 16px; /* Increased padding to create more space around the image */
  border-radius: 0px; /* More rounded corners */
  aspect-ratio: 1007 / 721; /* Aspect ratio to match the image (width/height) */
  overflow: hidden; /* Ensure no overflow */
  box-sizing: border-box; /* Include padding and borders in element's width and height */
  user-select: none;
  padding-top: 4rem; /* Increase top padding to make room for the title */
}

/* Background Container */
.background-container-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  overflow: hidden;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.5s ease-in-out; /* This matches the 500ms timeout in handleStepChange */
}

.background-container.fade-in {
  opacity: 1;
}

.background-container.fade-out {
  opacity: 0;
}

/* Steps Container */
.steps-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 90%;
  padding: 0 20px;
  box-sizing: border-box;
  user-select: none;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

/* Show steps container on guide container hover */
.guide-container:hover .steps-container {
  opacity: 1;
}

/* Step Rectangles */
.step-rectangle {
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
  border-radius: 8px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all changes */
  flex: 1;
  position: relative;
}

/* Active State */
.step-rectangle.active {
  height: 15px; /* Make active step taller */
  background-color: #FF4500; /* Orange color */
  opacity: 1; /* Ensure 100% opacity for active step */
}

/* Hover State */
.step-rectangle:hover {
  height: 15px;
  background-color: #FF4500; /* Orange color */
  opacity: 1;
}

/* Active and Hover State */
.step-rectangle.active:hover {
  height: 15px; /* Maintain height when active step is hovered */
}

/* Step Rectangles */
.step-rectangle-inner {
  width: 100%;
  height: 100%;
  background-color: #CCCCCC;
  border-radius: 8px;
  transition: height 0.3s ease, background-color 0.3s ease;
}

/* Active and Hover States */
.step-rectangle.active,
.step-rectangle:hover {
  background-color: rgb(255, 68, 0); /* Semi-transparent orange */
}

.step-rectangle.active .step-rectangle-inner,
.step-rectangle:hover .step-rectangle-inner {
  height: 15px; /* Increased height for active and hover states */
  background-color: #ff4400; /* Solid orange for the inner part */
}

/* Start Overlay */
.start-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.start-overlay.hidden {
  opacity: 0;
  visibility: hidden;
}


/* Pop-up */
.pop-up {
  position: absolute;
  width: 20%; /* Adjust this value to set the size relative to the container */
  max-width: 300px;
  padding: 10px;
  background-color: #1E1E1E;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  white-space: pre-wrap;
  font-size: 1vw; /* Responsive font size */
  transition: opacity 0.5s ease;
}

/* Fade animations */
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/* End Overlay */
.end-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out, backdrop-filter 1s ease-in-out;
  backdrop-filter: blur(0px);
}

.end-overlay.visible {
  opacity: 1;
  backdrop-filter: blur(10px);
}

.restart-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  opacity: 0;
  width: 150px;
  height: 150px;
  transition: transform 0.3s ease, opacity 1s ease-in-out, filter 0.3s ease;
  transform: scale(0.9);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.restart-button::-moz-focus-inner {
  border: 0;
}

.restart-button.fade-in {
  opacity: 1;
  transform: scale(1);
}

.restart-button:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0 10px rgba(255, 69, 0, 0.7)); /* Orange glow */
}

.restart-button:focus,
.restart-button:active {
  outline: none;
}

.restart-button svg {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, fill 0.3s ease;
  fill: #FF4500; /* Set the initial color of the SVG */
}

.restart-button:hover svg {
  transform: rotate(180deg);
  fill: #FF6347; /* Lighter orange color on hover */
}

/* Navigation Arrows */
.guide-container {
  position: relative;
}

.guide-container:hover .navigation-arrow {
  opacity: 0.7;
}

.navigation-arrow {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.navigation-arrow:hover {
  opacity: 1;
}

/* Step Titles */
.step-rectangle {
  position: relative;
  /* ... (existing styles) */
}

.step-title {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.0);
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease forwards;
  font-weight: 1000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Guide Title */
.guide-title {
  color: var(--color-primary); /* Use the primary color variable */
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
  user-select: none;
}

