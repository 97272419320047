/* src/styles/admin.css */

@import './main.css'; /* Import main styles if needed */

.admin-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  font-family: var(--font-primary);
}

.admin-sidebar {
  width: 250px;
  background-color: var(--color-section-2-bg);
  padding: 20px;
  box-sizing: border-box;
}

.admin-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.admin-logo a {
  color: var(--color-primary);
  text-decoration: none;
}

.admin-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-nav li {
  margin-bottom: 15px;
}

.admin-nav a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 18px;
}

.admin-nav a:hover {
  color: var(--color-primary);
}

.admin-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin-header {
  height: 60px;
  background-color: var(--color-section-3-bg);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.admin-header-right {
  display: flex;
  align-items: center;
}

.admin-logout-button,
.admin-login-link {
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.admin-logout-button:hover,
.admin-login-link:hover {
  color: var(--color-primary);
}

.admin-content {
  flex: 1;
  padding: 20px;
  background-color: var(--color-background);
}

.admin-dashboard h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.admin-metrics {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-metric-card {
  flex: 1;
  background-color: var(--color-card-bg);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.admin-metric-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.admin-metric-card p {
  font-size: 48px;
  margin: 0;
}

.admin-recent-activities {
  background-color: var(--color-card-bg);
  padding: 20px;
  border-radius: 8px;
}

.admin-recent-activities h2 {
  margin-top: 0;
}

.admin-recent-activities ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-recent-activities li {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
}

.admin-recent-activities li:last-child {
  border-bottom: none;
}
